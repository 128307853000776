import { seModule } from '@/utils/module.js'
import antLocal from "ant-design-vue/es/locale/en_US";

const requireComponent  = require.context('./en-US',false,/\.js$/);
const modulesFiles = [];

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  modulesFiles.push(componentConfig);
});

const enUS = seModule(modulesFiles)

export default {
  ...enUS,
  antLocal
}
